import { reactive, watch } from "vue";
import { Inspection } from "@/xlsx/database";

const FILTER_CAMPAIN_DEFAULT: Partial<Record<keyof Inspection,  Filter<keyof Inspection>[]>> = {
  type: [
    {
      value: "IQOA",
      isSelected: true,
    },
    {
      value: "IDP",
      isSelected: true,
    }
  ],
  campaign: [
    {
      value: "2023",
      isSelected: false,
    },
    {
      value: "2024",
      isSelected: false,
    },
    {
      value: "2025",
      isSelected: true,
    }
  ]
}
interface Filter<T extends keyof Inspection> {
  value: Inspection[T],
  isSelected: boolean,
};
export interface InspectionList {
  inspections: Inspection[];
  filteredInspections: Inspection[];
  filters: Partial<Record<keyof Inspection,  Filter<keyof Inspection>[]>>;
  initialize: (inspections: Inspection[]) => void;
  filterInspections: () => void;
  addToFilter: (key: keyof Inspection, value: Inspection[keyof Inspection]) => void;
}

export const inspectionList = reactive<InspectionList>({
  inspections: [],
  filteredInspections: [],
  filters: FILTER_CAMPAIN_DEFAULT,
  initialize(inspections) {
    this.inspections = inspections;
    this.filteredInspections = inspections;
    for(const inspection of inspections) {
      this.addToFilter("type", inspection.type);
      this.addToFilter("campaign", inspection.campaign);
    }
  },
  filterInspections() {
    this.filteredInspections = this.inspections.filter((inspection) => {
      const filterType = this.filters.type?.find((filter) => filter.value == inspection.type);
      const filterCampaign = this.filters.campaign?.find((filter) => filter.value == inspection.campaign);
      if(filterType && filterType.isSelected && filterCampaign && filterCampaign.isSelected ) {
        return true;
      } else {
        return false;
      }
    });
  },
  addToFilter(key, value) {
    const idx = this.filters[key]?.findIndex((filter) => filter.value == value);
    if(idx == -1) {
      this.filters[key]?.push({
        value: value,
        isSelected: true,
      })
    }
  },
});

watch((inspectionList.filters), (newFilter) => {
 inspectionList.filterInspections();
});
